<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{title}}管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane :label="'现有'+title" icon="ios-warning">
            <query-table
              :columnsFormatData="columnsFormatData"
              :askData="code"
              :refreshData="update_count"
              main_width="1260px"
            ></query-table>
          </TabPane>
          <Button slot="extra" @click="openModal('create')">添加</Button>
        </Tabs>
      </div>
    </div>
    <Modal
      v-model="modalSw.create"
      width="800"
      :mask-closable="false"
      :styles="{top:0}"
      @on-cancel="closeModal('create')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>新增{{ title }}</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="create"
            :model="newItem"
            :rules="ruleValidate"
            :label-width="180"
          >
          <FormItem label="办事处地点：" prop="location">
              <Input v-model="newItem.location" placeholder="" />
            </FormItem>
            <FormItem :label="ele.label+'：'" v-for="(ele,index) in newItem.content" :key="index">
              <span>{{ele.value}}</span><Button class="del-button" @click="delContentByCreate(index)" type="text" icon="ios-trash"
                            size="large"></Button>
            </FormItem>
            <div class="latence-wrapper">
              <input type="text" placeholder="自定义属性名称" v-model="tmpLabel" class="s-input-left"/>
            <input type="text" placeholder="属性值" v-model="tmpValue" class="s-input-right"/>
            <Button class="del-button" @click="addContentByCreate" type="text" >添加</Button></div>
            <FormItem label="语言版本：" prop="lang">
              <Select
                v-model="newItem.lang"
                style="width: 200px"
              >
                <Option
                  v-for="(item,index) in option.lang"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}</Option
                >
              </Select>
            </FormItem>
          </Form>
          <Spin fix v-show="loadingStatus"></Spin>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleCreate('create')">提交</Button>
        <Button @click="handleReset('create')" style="margin-left: 8px"
          >重置</Button
        >
        <Button size="large" @click="closeModal('create')">取消</Button>
      </div>
    </Modal>
    <Modal
      v-model="modalSw.alter"
      width="800"
      :mask-closable="false"
      @on-cancel="closeModal('alter')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>{{title}}修改--{{currentItem.name}}</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="alter"
            :model="currentItem"
            :rules="ruleValidate"
            :label-width="150"
          >
            <FormItem label="办事处地点：" prop="location">
              <Input v-model="currentItem.location" placeholder="" />
            </FormItem>
            <FormItem :label="ele.label+'：'" v-for="(ele,index) in currentItem.content" :key="index">
              <span>{{ele.value}}</span><Button class="del-button" @click="delContentByAlter(index)" type="text" icon="ios-trash"
                            size="large"></Button>
            </FormItem>
            <div class="latence-wrapper">
              <input type="text" placeholder="自定义属性名称" v-model="tmpLabel" class="s-input-left"/>
            <input type="text" placeholder="属性值" v-model="tmpValue" class="s-input-right"/>
            <Button class="del-button" @click="addContentByAlter" type="text" >添加</Button></div>
            <FormItem label="语言版本：" prop="lang">
              <Select
                v-model="currentItem.lang"
                style="width: 260px"
              >
                <Option
                  v-for="(item,index) in option.lang"
                  :value="item.value"
                  :key="index"
                  >{{ item.text }}</Option
                >
              </Select>
            </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="handleAlter('alter')">确定</Button>
        <Button size="large" @click="closeModal('alter')">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";
import {manageFuncs} from "@/libs/mixins/ManageFuncs.js";

let userToken = localStorage.getItem("current_user_token");

// let authData = JSON.parse(localStorage.getItem("authData"));

function itemFac() {
  return {
    id: 0,
    location:"",
    content:[],
    stat:"",
    lang:"",
  };
}


export default {
  name: "officesManage",
  components: {
    queryTable,
  },
  props: {},
  mixins:[manageFuncs],
  data() {
    return {
      title:"办事处",
      code:"offices",
      modalSw:{alter:false,create:false,},
      update_count: 0,
      newItem: itemFac(),
      currentItem: itemFac(),
      tmpLabel:"",
      tmpValue:"",
      columnsFormatData: [
        {
          title: "办事处地点",
          key: "location",
          align: "center",
        },
        {
          title: "内容",
          key: "content",
          align: "center",
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width: 120,
        },
        {
          title: "语言版本",
          key: "lang",
          align: "center",
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          width: 160,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      ruleValidate: {
        location: [
          {
            required: true,
            type: "string",
            message: "请输入办事处地点！",
            trigger: "blur",
          },
        ],
        lang: [
          {
            required: true,
            type: "string",
            message: "请选择语言版本！",
            trigger: "blur",
          },
        ],
      },
      option: {
      },
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getOption();
  },
  methods: {
    getOption() {
      this.$axios
        .post(this.$url + "/option", {
          userToken: userToken,
          askData: this.code,
        })
        .then((response) => {
          this.option = response.data.res_record;
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    addContentByCreate(){
      if(this.tmpLabel&&this.tmpValue){
        this.newItem.content.push({label:this.tmpLabel,value:this.tmpValue});
        this.tmpLabel = "";
        this.tmpValue = "";
      }else{
        this.$Message.error("新添加的值不可为空！");
      }
      
    },
    addContentByAlter(){
      if(this.tmpLabel&&this.tmpValue){
        this.currentItem.content.push({label:this.tmpLabel,value:this.tmpValue});
        this.tmpLabel = "";
        this.tmpValue = "";
      }else{
        this.$Message.error("新添加的值不可为空！");
      }
    },
    delContentByCreate(index){
      this.newItem.content.splice(index,1);
    },
    delContentByAlter(index){
      this.currentItem.content.splice(index,1);
    }, 
    handleCreate(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "add");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + '/' + this.code, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title+"添加成功！");
                this.update_count++;
                this.newItem = itemFac();
                this.closeModal(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleAlter(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alter");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + '/' + this.code, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title+"修改成功！");
                this.update_count++;
                this.closeModal(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    openModal(label){
      this.modalSw[label] = true;
    },
    closeModal(label) {
      this.modalSw[label] = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条内容：",
        content:
          "<p>" +
          "名称：" +
          value.name +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + '/' + this.code, {
          userToken: userToken,
          askData: "del",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      for(let i in this.currentItem){
      if(i!=='content'){
       this.currentItem[i] = value[i];
         }else{
           this.currentItem.content = JSON.parse(value.content);
         }
       }
      this.openModal('alter');
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}


.form-wrapper {
  text-align: left;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

.img-outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.img-wrapper {
  width: 50px;
  min-height: 50px;
  margin: 0 5px;
}

.img-wrapper-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.img-a {
  border: 1px solid #ccc;
  margin: 0 5px;
  height: fit-content;
  position: relative;
}

.img-a span {
  display: none;
  position: absolute;
  font-size: 8px;
  right: 10px;
  top: 5px;
}

.img-a:hover span {
  display: inline-block;
}

input {
  display: inline-block;
  width: 100%;
  height: 32px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 14px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

input:focus {
  border-color: #57a3f3;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
}

input:hover {
  border-color: #57a3f3;
}

.latence-wrapper{
  padding-bottom: 30px;
}

input.s-input-left{
  width:170px;
  text-align:right;
  padding:0 10px;
  margin-right:10px;
}

input.s-input-right{
  width:250px;
  text-align:left;
  padding:0 10px;
}
</style>